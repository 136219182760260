exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-english-post-01-tsx": () => import("./../../../src/pages/english/post01.tsx" /* webpackChunkName: "component---src-pages-english-post-01-tsx" */),
  "component---src-pages-english-post-02-tsx": () => import("./../../../src/pages/english/post02.tsx" /* webpackChunkName: "component---src-pages-english-post-02-tsx" */),
  "component---src-pages-english-post-03-tsx": () => import("./../../../src/pages/english/post03.tsx" /* webpackChunkName: "component---src-pages-english-post-03-tsx" */),
  "component---src-pages-english-post-04-tsx": () => import("./../../../src/pages/english/post04.tsx" /* webpackChunkName: "component---src-pages-english-post-04-tsx" */),
  "component---src-pages-english-post-05-tsx": () => import("./../../../src/pages/english/post05.tsx" /* webpackChunkName: "component---src-pages-english-post-05-tsx" */),
  "component---src-pages-english-post-06-tsx": () => import("./../../../src/pages/english/post06.tsx" /* webpackChunkName: "component---src-pages-english-post-06-tsx" */),
  "component---src-pages-english-post-07-tsx": () => import("./../../../src/pages/english/post07.tsx" /* webpackChunkName: "component---src-pages-english-post-07-tsx" */),
  "component---src-pages-english-story-01-tsx": () => import("./../../../src/pages/english/story01.tsx" /* webpackChunkName: "component---src-pages-english-story-01-tsx" */),
  "component---src-pages-english-story-02-tsx": () => import("./../../../src/pages/english/story02.tsx" /* webpackChunkName: "component---src-pages-english-story-02-tsx" */),
  "component---src-pages-german-post-01-tsx": () => import("./../../../src/pages/german/post01.tsx" /* webpackChunkName: "component---src-pages-german-post-01-tsx" */),
  "component---src-pages-german-post-02-tsx": () => import("./../../../src/pages/german/post02.tsx" /* webpackChunkName: "component---src-pages-german-post-02-tsx" */),
  "component---src-pages-german-post-03-tsx": () => import("./../../../src/pages/german/post03.tsx" /* webpackChunkName: "component---src-pages-german-post-03-tsx" */),
  "component---src-pages-german-post-04-tsx": () => import("./../../../src/pages/german/post04.tsx" /* webpackChunkName: "component---src-pages-german-post-04-tsx" */),
  "component---src-pages-german-post-05-tsx": () => import("./../../../src/pages/german/post05.tsx" /* webpackChunkName: "component---src-pages-german-post-05-tsx" */),
  "component---src-pages-german-post-06-tsx": () => import("./../../../src/pages/german/post06.tsx" /* webpackChunkName: "component---src-pages-german-post-06-tsx" */),
  "component---src-pages-german-post-07-tsx": () => import("./../../../src/pages/german/post07.tsx" /* webpackChunkName: "component---src-pages-german-post-07-tsx" */),
  "component---src-pages-german-story-01-tsx": () => import("./../../../src/pages/german/story01.tsx" /* webpackChunkName: "component---src-pages-german-story-01-tsx" */),
  "component---src-pages-german-story-02-tsx": () => import("./../../../src/pages/german/story02.tsx" /* webpackChunkName: "component---src-pages-german-story-02-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-pipeline-tsx": () => import("./../../../src/pages/pipeline.tsx" /* webpackChunkName: "component---src-pages-pipeline-tsx" */),
  "component---src-pages-polish-post-01-tsx": () => import("./../../../src/pages/polish/post01.tsx" /* webpackChunkName: "component---src-pages-polish-post-01-tsx" */),
  "component---src-pages-polish-post-02-tsx": () => import("./../../../src/pages/polish/post02.tsx" /* webpackChunkName: "component---src-pages-polish-post-02-tsx" */),
  "component---src-pages-polish-post-03-tsx": () => import("./../../../src/pages/polish/post03.tsx" /* webpackChunkName: "component---src-pages-polish-post-03-tsx" */),
  "component---src-pages-polish-post-04-tsx": () => import("./../../../src/pages/polish/post04.tsx" /* webpackChunkName: "component---src-pages-polish-post-04-tsx" */),
  "component---src-pages-polish-post-05-tsx": () => import("./../../../src/pages/polish/post05.tsx" /* webpackChunkName: "component---src-pages-polish-post-05-tsx" */),
  "component---src-pages-polish-post-06-tsx": () => import("./../../../src/pages/polish/post06.tsx" /* webpackChunkName: "component---src-pages-polish-post-06-tsx" */),
  "component---src-pages-polish-post-07-tsx": () => import("./../../../src/pages/polish/post07.tsx" /* webpackChunkName: "component---src-pages-polish-post-07-tsx" */),
  "component---src-pages-polish-post-08-tsx": () => import("./../../../src/pages/polish/post08.tsx" /* webpackChunkName: "component---src-pages-polish-post-08-tsx" */),
  "component---src-pages-polish-story-01-tsx": () => import("./../../../src/pages/polish/story01.tsx" /* webpackChunkName: "component---src-pages-polish-story-01-tsx" */),
  "component---src-pages-polish-story-02-tsx": () => import("./../../../src/pages/polish/story02.tsx" /* webpackChunkName: "component---src-pages-polish-story-02-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

